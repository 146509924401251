<template>
  <div class="name-list">
    <en-tabs
      :list="tabList"
      type="white"
      v-model="activeTab"
      @change="changeTab"
    >
      <div slot="right" class="setting-right">
        <span class="del-icon" @click="del">
          <i class="iconfont">&#xebd9;</i>删除
        </span>
        <en-button style="height: 30px;font-size: 12px;" icon="add" iconSize="12" @click="addNameList">添加</en-button>
      </div>
    </en-tabs>
    <div class="table-wrap">
      <en-table ref="enTable" :page-config="{ pageModel: mailListData, changeMethod: handlePageChanged }" :select-config="{ reserve: true }"
        :data="mailListData.records" :loading="loading" :height-config="{ bottomHeight }" @select-change="handleSelectChange" auto-resize>
        <en-table-column type="index-selection"></en-table-column>
        <en-table-column title="邮箱" field="mailAccount"></en-table-column>
        <template #hoverRow="{row, rowIndex}">
          <en-expand-buttons
            :data="getButtonData(row)"
            @button-click="
              handleButtonClick({
                row,
                rowIndex,
                btnData: arguments[1],
                btnIndex: arguments[0]
              })
            "
          >
          </en-expand-buttons>
        </template>
         <template #paginationLeft>
            <div class="selection">
              已选: <span>{{checkResult.length}}</span> 条数据
              <en-button text type="primary" @click="clearSelection">清空</en-button>
            </div>
        </template>
      </en-table>
      <!-- <en-pagination :page-model="mailListData" @change="handlePageChanged" v-show="mailListData.records.length">
        <template slot="left">
          <div v-show="checkResult.length" class="selection">
            已选: <span>{{checkResult.length}}</span> 条数据
            <en-button text type="primary" @click="clearSelection">清空</en-button>
          </div>
        </template>
      </en-pagination> -->
    </div>
    <name-list-dialog ref="nameListDialog" :is-black-list="activeTab === '1'" @confirmAdd="confirmAdd"></name-list-dialog>
  </div>
</template>
<script>
import { mailSettingService } from "@/api/mailBox/mailSetting";
import NameListDialog from "../../components/MailSetting/NameListDialog";

export default {
  name: "NameListSetting",
  components: {
    NameListDialog
  },
  data() {
    return {
      activeTab: "1",
      tabList: [
        {
          id: "1",
          name: "黑名单"
        },
        {
          id: "2",
          name: "白名单"
        }
      ],
      loading: false,
      mailListData: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
        totalPages: 0,
        records: []
      },
      checkResult: []
    };
  },
  computed: {
    bottomHeight() {
      if (this.mailListData.records.length) {
        return 18;
      }
      return 18;
    }
  },
  mounted() {
    this.getMailList();
  },
  methods: {
    // 清除选中的项目
    clearSelection() {
      this.checkResult = [];
      this.$refs.enTable.setAllSelection(false);
    },
    async confirmAdd(addressList) {
      console.log(addressList);
      addressList = addressList.map((item) => ({
        ...item,
        type: Number(this.activeTab)
      }));
      const res = await mailSettingService.addMailMarkAddress({ addressList: JSON.stringify(addressList) });
      this.refreshData();
      console.log(res);
    },
    async getMailList() {
      const { pageNo, pageSize } = this.mailListData;
      this.loading = true;
      const res = await mailSettingService.queryMailMarkAddress({
        pageNo, pageSize, type: this.activeTab
      });
      this.loading = false;
      console.log(res);
      Object.assign(this.mailListData, res);
    },
    addNameList() {
      this.$refs.nameListDialog.show();
    },
    changeTab() {
      this.refreshData();
    },
    refreshData() {
      this.mailListData.pageNo = 1;
      this.mailListData.pageSize = 10;
      this.clearSelection();
      this.getMailList();
    },
    // 根据类型获取行悬停按钮
    getButtonData() {
      return [
        {
          icon: "iconshanchu-liebiao",
          name: "删除",
          iconColor: "#f76b6b"
        }
      ];
    },
    // 分页器事件
    handlePageChanged({ pageSize, pageNo }) {
      console.log(pageSize, pageNo);
      this.mailListData.pageSize = pageSize;
      this.mailListData.pageNo = pageNo;
      this.getMailList();
    },
    handleButtonClick({ rowIndex }) {
      console.log(rowIndex);
      this.delSure(this.mailListData.records[rowIndex].id);
    },
    // check row
    handleSelectChange({ selection }) {
      this.checkResult = selection;
    },
    buttonDataMethod({ row }) {
      return this.buttonData.slice(0, row.id);
    },
    // 清空选中
    clearSelected() {},
    // 删除
    del() {
      const ids = this.checkResult.map((item) => item.id).join(",");
      if (!ids || !ids.length) {
        this.$message({
          message: "请选择邮箱",
          type: "warning"
        });
        return;
      }
      this.delSure(ids);
    },
    async delSure(ids) {
      try {
        await mailSettingService.deleteMailMarkAddress({
          ids
        });
        this.$message({
          message: "删除成功",
          type: "success"
        });
        this.refreshData();
      } catch (error) {
        console.log("error");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.name-list {
  .setting-right {
    .del-icon {
      font-size: 12px;
      color: #636c78;
      margin-right: 16px;
      cursor: pointer;
      .iconfont {
        position: relative;
        top: 1px;
        font-size: 16px;
        color: #f76b6b;
        margin-right: 2px;
      }
    }
  }
  .table-wrap {
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.name-list {
  .table-wrap {
    .image-and-text {
      margin-right: 18px;
    }
    .selection {
      font-size: 12px;
      color: #606060;
      span {
        color: #4695DF;
      }
      .en-button {
        margin-left: 10px;
        padding-bottom: 2px;
      }
    }
  }
  .tabs-active {
    font-weight: bold;
  }
}
</style>
