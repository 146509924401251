<template>
  <div class="receive-setting">
    <div class="title">收发邮件设置</div>
    <div class="setting-container">
      <div class="field-item">
        <span class="label">收件人发送已读回执</span>
        <el-select v-model="mailConfig.mailNeedReceipt" placeholder="请选择" style="width: 600px;">
          <el-option
            v-for="item in receiveOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="field-item">
        <span class="label">发送已读回执</span>
        <el-select v-model="mailConfig.mailReceiptType" placeholder="请选择" style="width: 600px;">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mailSettingService } from "@/api/mailBox/mailSetting";

export default {
  name: "ReceiveSetting",
  data() {
    return {
      mailConfig: {
        mailNeedReceipt: "0",
        mailReceiptType: "3"
      },
      receiveOptions: [
        {
          label: "不需要收件人发送已读回执",
          value: "1"
        },
        {
          label: "需要收件人发送已读回执",
          value: "0"
        }
      ],
      options: [
        {
          label: "始终不发送回执",
          value: "1"
        },
        {
          label: "始终发送回执",
          value: "2"
        },
        {
          label: "有需要时发送回执",
          value: "3"
        }
      ]
    };
  },
  mounted() {
    this.getMailConfig();
  },
  computed: {
    ...mapGetters("mailBox", [
      "userMailConfig"
    ])
  },
  watch: {
    // userMailConfig(newVal) {
    //   this.mailConfig = { ...newVal };
    // },
    mailConfig: {
      deep: true,
      handler(newVal) {
        console.log(newVal);
        const configList = Object.keys(newVal).map((key) => ({
          configKey: key,
          configValue: newVal[key],
          type: "MAILUSERCONFIG"
        }));
        console.log(configList);
        mailSettingService.saveUserConfigMultiple({ configList: JSON.stringify(configList) }).then(() => {
          this.setUserMailConfig(newVal);
        });
      }
    }
  },
  methods: {
    ...mapMutations("mailBox", [
      "setUserMailConfig"
    ]),
    async getMailConfig() {
      const res = await mailSettingService.queryUserConfigMultiple({
        type: "MAILUSERCONFIG"
      });
      if (res) {
        this.setUserMailConfig({
          ...this.userMailConfig,
          ...res
        });
        this.mailConfig = { ...this.userMailConfig };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.receive-setting {
  .title {
    color: #333333;
    height: 50px;
    line-height: 50px;
    text-indent: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8ecf2;
    font-weight: bold;
    font-size: 14px;
  }
  .setting-container {
    padding-top: 18px;
    .field-item {
      display: flex;
      margin-bottom: 4px;
      height: 48px;
      line-height: 48px;
      .label {
        display: inline-block;
        font-size: 12px;
        width: 128px;
        color: #333;
        text-align: right;
        margin-right: 10px;
      }
    }
  }
}
</style>
