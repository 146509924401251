var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mail-setting-wrapper" },
    [
      _c("top-header", {
        attrs: { "nav-name": _vm.navName },
        on: { backHandle: _vm.backHandle },
      }),
      _c(
        "div",
        { staticClass: "mail-setting-container" },
        [
          _c("container-left"),
          _c("div", { staticClass: "container-right" }, [_c("router-view")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }