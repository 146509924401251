<template>
  <div class="account-manage">
    <div class="title">
      <span>账号管理</span>
      <en-button style="height: 30px;font-size: 12px;" @click="addAccount" iconSize="12" icon="add">添加</en-button>
    </div>
    <en-result type="NoData" v-show="accountList.length === 0 && !loading"></en-result>
    <draggable
      class="account-container draggable"
      group="field"
      v-model="accountList"
      v-loading="loading"
      @end="cardMove"
      v-bind="dragOptions"
    >
      <en-card
        class="account-card"
        v-for="(card, index) in accountList"
        :key="card.id"
        type="big"
        :class="{'error-card': errorAccountList.find(item => item.errorMailAccountId === card.id)}"
        :title="card.mailFullAccount"
        :data-info="card"
        :sub-title="'创建时间：' + card.createTime"
        :icon-name="card.createType === 0 ? 'iconqiyezhanghao' : 'icongerenyouxiang'"
        :icon-color="computedColor(card, index)"
        edit-able="true"
        :disabled="card.status === 1"
        :menu-list="menuList(card)"
        @change="handlerCallback"
      >
        <template slot="tag">
          <span class="default-tag" v-if="card.isDefault === 0">
            <span class="default-text">默</span>
            <span class="default-bg"></span>
          </span>
        </template>
      </en-card>
    </draggable>
    <create-account-dialog :dialog-type="dialogType" ref="accountDialog" @submitForm="submitForm"></create-account-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mailSettingService } from "@/api/mailBox/mailSetting";
import CreateAccountDialog from "../../components/MailSetting/CreateAccountDialog";

export default {
  name: "AccountManage",
  components: {
    draggable,
    CreateAccountDialog
  },
  data() {
    return {
      // 弹窗类型 添加or编辑
      dialogType: "add",
      accountList: [],
      errorAccountList: [],
      loading: false,
      defaultServerSettings: {
        popServerAddress: "",
        popPort: "",
        popUseSsl: 0,
        smtpServerAddress: "",
        smtpPort: "",
        smtpPassword: "",
        isMailPwd: 1,
        smtpUseSsl: 1
      },
      dragOptions: {
        sort: true,
        animation: 500
        // filter: ".disabled"
      } // 拖拽的配置信息
    };
  },
  mounted() {
    this.getAccountList();
    this.receiveMail();
    this.getMailServerSettings();
  },
  methods: {
    async receiveMail() {
      const res = await mailSettingService.receiveMail();
      this.errorAccountList = res || [];
    },
    computedColor(card, index) {
      if (card.createType === 0) {
        return "#3e90fe";
      }
      const colors = ["#26c393", "#f76b6b", "#f6bd8e"];
      return colors[index % 3];
    },
    addAccount() {
      this.dialogType = "add";
      this.$nextTick(() => {
        this.$refs.accountDialog.show({
          mailFullAccount: "",
          mailPassword: "",
          isDefault: 1,
          intervalMinutes: 0,
          isHistory: -1,
          latestNum: 0,
          serverSettings: this.defaultServerSettings
        });
      });
    },
    async submitForm(data) {
      console.log(data);
      const accountDialogRef = this.$refs.accountDialog;
      if (data.isHistory === -1) {
        data.latestNum = -1;
      }
      if (this.dialogType === "add") {
        try {
          const res = await mailSettingService.addMailAccountByGeneral(data);
          this.$message.success("添加成功");
          console.log(res);
        } catch (error) {
          accountDialogRef.btnLoading = false;
          return;
        }
      }
      if (this.dialogType === "update") {
        try {
          await mailSettingService.updateMailAccountByGeneral(data);
          this.$message.success("更新成功");
        } catch (error) {
          accountDialogRef.btnLoading = false;
          return;
        }
      }
      accountDialogRef.hide();
      accountDialogRef.reset();
      this.getAccountList();
    },
    async handlerCallback({ oparation }, card) {
      console.log(oparation);
      console.log(card);
      // 禁用启用
      if (oparation === "enable" || oparation === "disable") {
        const res = await mailSettingService.setMailAccountStatusByGeneral({
          id: card.id,
          isDefault: card.isDefault,
          status: oparation === "enable" ? 0 : 1
        });
        console.log(res);
        card.status = oparation === "enable" ? 0 : 1;
        this.$message.success(oparation === "enable" ? "启用成功" : "停用成功");
      }
      // 删除
      if (oparation === "del") {
        this.$confirm("确定删除该账号？", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async() => {
          await mailSettingService.deleteMailAccountByGeneral({ id: card.id });
          this.accountList = this.accountList.filter((item) => item.id !== card.id);
          this.$message.success("删除成功");
        });
      }
      // 设置默认
      if (oparation === "setDefault") {
        await mailSettingService.updateMailAccountByGeneral({
          id: card.id, isDefault: 0, mailFullAccount: card.mailFullAccount
        });
        this.accountList.forEach((item) => {
          item.isDefault = 1;
        });
        card.isDefault = 0;
        this.$message.success("设置默认邮箱成功");
      }
      // 编辑账号
      if (oparation === "edit") {
        this.dialogType = "update";
        this.$nextTick(() => {
          this.$refs.accountDialog.show(card);
        });
      }
    },
    async cardMove() {
      await mailSettingService.sortMailAccountStatusByGeneral({
        ids: this.accountList.map((item) => item.id).join(",")
      });
    },
    menuList({ createType, status, isDefault }) {
      let res = [];
      if (createType === 0) {
        res = [
          {
            oparation: status ? "enable" : "disable",
            name: status ? "启用" : "停用"
          }
        ];
      } else {
        res = [
          {
            oparation: "edit",
            name: "编辑"
          },
          {
            oparation: status ? "enable" : "disable",
            name: status ? "启用" : "停用"
          },
          {
            oparation: "del",
            name: "删除"
          }
        ];
      }
      if (isDefault === 1) {
        res.unshift({
          oparation: "setDefault",
          name: "设为默认发件箱"
        });
      }
      return res;
    },
    async getAccountList() {
      this.loading = true;
      const res = await mailSettingService.queryMailAccountList({ pageNo: 1, pageSize: 1000 });
      this.loading = false;
      this.accountList = res.records;
    },
    async getMailServerSettings() {
      const res = await mailSettingService.getMailServerSettings();
      Object.assign(this.defaultServerSettings, res);
    }
  }
};
</script>

<style lang="scss" scoped>
.account-manage {
  height: 100%;
  .title {
    color: #333333;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8ecf2;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .account-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: calc(100% - 50px);
    overflow: auto;
    align-content: baseline;
    .account-card {
      width: 49.2% !important;
      margin-bottom: 12px;
      align-items: center;
      cursor: move;
      /deep/ .en-card-big-main-sub-title {
        margin-top: 14px;
      }
      .default-tag {
        display: inline-block;
        width: 30px;
        height: 30px;
        overflow: hidden;
        .default-bg {
          position: absolute;
          left: -30px;
          top: 0;
          width:0;
          height:0;
          border-right: 30px solid transparent;
          border-left: 30px solid transparent;
          border-top: 30px solid #f78429;
        }
        .default-text {
          position: absolute;
          z-index: 11;
          font-size: 12px;
          color: #fff;
          left: 2px;
          top: 2px;
          transform: scale(0.9);
        }
      }
    }
    .error-card {
      // border-color: #f76b6b;
    }
  }
}
</style>

<style lang="scss">
.en-card-big {
  .en-card-big-tag {
    overflow: hidden;
  }
}
</style>
