var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-left" }, [
    _c("div", { staticClass: "title" }, [_vm._v("常规设置")]),
    _c("div", { staticClass: "setting-list" }, [
      _c(
        "div",
        {
          staticClass: "setting-item",
          class: { "active-item": _vm.routeName === "AccountManage" },
          on: {
            click: function ($event) {
              return _vm.jump("/accountManage")
            },
          },
        },
        [
          _c("i", { staticClass: "iconfont icon-manage" }, [_vm._v("")]),
          _vm._v("账号管理 "),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "setting-item",
          class: { "active-item": _vm.routeName === "FilterMail" },
          on: {
            click: function ($event) {
              return _vm.jump("/filterMail")
            },
          },
        },
        [
          _c("i", { staticClass: "iconfont icon-filter" }, [_vm._v("")]),
          _vm._v("邮件过滤 "),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "setting-item",
          class: { "active-item": _vm.routeName === "NameListSetting" },
          on: {
            click: function ($event) {
              return _vm.jump("/nameListSetting")
            },
          },
        },
        [
          _c("i", { staticClass: "iconfont icon-name" }, [_vm._v("")]),
          _vm._v("黑/白名单 "),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "setting-item",
          class: { "active-item": _vm.routeName === "ReceiveSetting" },
          on: {
            click: function ($event) {
              return _vm.jump("/receiveSetting")
            },
          },
        },
        [
          _c("i", { staticClass: "iconfont icon-setting" }, [_vm._v("")]),
          _vm._v("收发邮件设置 "),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "setting-item",
          class: { "active-item": _vm.routeName === "AutoGraphSetting" },
          on: {
            click: function ($event) {
              return _vm.jump("/autoGraphSetting")
            },
          },
        },
        [
          _c("i", { staticClass: "iconfont icon-sign" }, [_vm._v("")]),
          _vm._v("签名设置 "),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }