var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        "custom-class": "name-list-dialog",
        visible: _vm.dialogVisible,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.titleInfo.title) + "   "),
          ]),
          _c(
            "el-tooltip",
            {
              staticClass: "tips",
              attrs: {
                effect: "dark",
                content: _vm.titleInfo.tips,
                placement: "bottom-start",
              },
            },
            [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filed-item" },
        [
          _c("span", { staticClass: "label" }, [_vm._v("邮箱")]),
          _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _c("input-tag", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.contactList,
                  callback: function ($$v) {
                    _vm.contactList = $$v
                  },
                  expression: "contactList",
                },
              }),
              _c(
                "span",
                { staticClass: "iconfont", on: { click: _vm.showMailList } },
                [_vm._v("")]
              ),
            ],
            1
          ),
          _c("mail-list-dialog", {
            ref: "mailListDialog",
            on: { confirmSelect: _vm.confirmSelect },
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "font-size": "12px" },
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.confirmAdd },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }