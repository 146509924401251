var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "receive-setting" }, [
    _c("div", { staticClass: "title" }, [_vm._v("收发邮件设置")]),
    _c("div", { staticClass: "setting-container" }, [
      _c(
        "div",
        { staticClass: "field-item" },
        [
          _c("span", { staticClass: "label" }, [_vm._v("收件人发送已读回执")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "600px" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.mailConfig.mailNeedReceipt,
                callback: function ($$v) {
                  _vm.$set(_vm.mailConfig, "mailNeedReceipt", $$v)
                },
                expression: "mailConfig.mailNeedReceipt",
              },
            },
            _vm._l(_vm.receiveOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "field-item" },
        [
          _c("span", { staticClass: "label" }, [_vm._v("发送已读回执")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "600px" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.mailConfig.mailReceiptType,
                callback: function ($$v) {
                  _vm.$set(_vm.mailConfig, "mailReceiptType", $$v)
                },
                expression: "mailConfig.mailReceiptType",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }