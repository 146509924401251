<template>
  <el-dialog
    top="10vh"
    :title="dialogTitle"
    custom-class="account-dialog"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="900px"
  >
    <div class="account-form">
      <div class="form-title">基础信息设置</div>
      <el-form label-position="right" ref="basicForm" :rules="rules" label-width="70px" :model="formData">
        <el-form-item label="邮箱地址" prop="mailFullAccount">
          <input type="password" style="border: none;height: 0;width: 0; display: inline;padding: 0;" >
          <el-input v-model="formData.mailFullAccount" placeholder="请输入邮箱地址"></el-input>
        </el-form-item>
        <el-form-item label="邮箱密码" prop="mailPassword">
          <input type="text" style="border: none;height: 0;width: 0; display: inline;padding: 0;" >
          <input-password :show-password="dialogType === 'add'"  v-model="formData.mailPassword" placeholder="请输入邮箱密码"></input-password>
          <!-- <el-input :show-password="dialogType === 'add'" type="password" v-model="formData.mailPassword" placeholder="请输入邮箱密码"></el-input> -->
        </el-form-item>
        <el-form-item label="其它选项">
          <p class="checkbox-item">
            <el-checkbox v-model="formData.intervalMinutes" :true-label="0" :false-label="1">定时收取邮件</el-checkbox>
          </p>
          <p class="checkbox-item">
            <el-checkbox v-model="formData.isHistory" :true-label="0" :false-label="-1">收取历史邮件</el-checkbox>
            <el-select style="width: 350px;margin-left: 12px;" v-show="formData.isHistory === 0" v-model="formData.latestNum" placeholder="请选择">
              <el-option
                v-for="item in historyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </p>
        </el-form-item>
      </el-form>
      <div class="border"></div>
      <div class="form-title fold-title" @click="serverFormVisible = !serverFormVisible">
        服务器设置
        <span class="el-icon-arrow-down" :class="{'rotate-arrow': !serverFormVisible}"></span>
      </div>
      <el-collapse-transition>
        <el-form v-show="serverFormVisible" label-position="right" ref="serverForm" :rules="rules" label-width="82px" :model="serverSettings">
          <el-form-item label="接收服务器" prop="popServerAddress">
            <el-input v-model="serverSettings.popServerAddress" placeholder="请输入POP服务器接收地址"></el-input>
          </el-form-item>
          <el-form-item label="端口号" prop="popPort">
            <el-input v-model="serverSettings.popPort" placeholder="请输入POP服务器端口号"></el-input>
            <div style="margin: 10px 0 -10px 0;">
              <el-checkbox v-model="serverSettings.popUseSsl" :true-label="0" :false-label="1">始终使用安全连接SSL</el-checkbox>
            </div>
          </el-form-item>
          <el-form-item label="发送服务器" prop="smtpServerAddress">
            <el-input v-model="serverSettings.smtpServerAddress" placeholder="请输入SMTP服务器发送地址"></el-input>
          </el-form-item>
          <el-form-item label="端口号" prop="smtpPort">
            <el-input v-model="serverSettings.smtpPort" placeholder="请输入SMTP服务器端口号"></el-input>
          </el-form-item>
          <el-form-item label="SMTP密码" prop="smtpPassword">
            <!-- <el-input v-model="serverSettings.smtpPassword" show-password :disabled="serverSettings.isMailPwd === 0" placeholder="SMTP默认沿用邮箱密码"></el-input> -->
            <input type="text" style="border: none;height: 0;width: 0; display: inline;padding: 0;" >
            <input-password :disabled="serverSettings.isMailPwd === 0"  v-model="serverSettings.smtpPassword" placeholder="SMTP默认沿用邮箱密码"></input-password>
            <p style="margin-top: 10px;">
              <el-checkbox v-model="serverSettings.isMailPwd" :true-label="0" :false-label="1">SMTP默认沿用邮箱密码</el-checkbox>
            </p>
            <p>
              <el-checkbox v-model="serverSettings.smtpUseSsl" :true-label="0" :false-label="1">始终使用安全链接SSL</el-checkbox>
            </p>
          </el-form-item>
        </el-form>
      </el-collapse-transition>
      <div v-show="!serverFormVisible" class="seat"></div>
    </div>
    <span slot="footer">
      <en-button type="primary" style="font-size: 12px;" :loading="btnLoading" @click="submitHandler" size="medium">确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import InputPassword from "./InputPassword";

const serverSettings = {
  popServerAddress: "",
  popPort: "",
  popUseSsl: 1,
  smtpServerAddress: "",
  smtpPort: "",
  smtpPassword: "",
  isMailPwd: 1,
  smtpUseSsl: 1
};
const formData = {
  mailFullAccount: "",
  mailPassword: "",
  isDefault: 1,
  intervalMinutes: 0,
  isHistory: -1,
  latestNum: 0
};

export default {
  name: "CreateAccountDialog",
  components: {
    InputPassword
  },
  props: {
    dialogType: {
      type: String,
      default: "add"
    }
  },
  computed: {
    dialogTitle() {
      if (this.dialogType === "add") {
        return "新建账号";
      }
      return "编辑账号";
    }
  },
  watch: {
    dialogType() {
      this.serverSettings = { ...serverSettings };
      this.formData = { ...formData };
    },
    // eslint-disable-next-line object-shorthand
    "serverSettings.isMailPwd"(newVal) {
      if (newVal) {
        this.rules.smtpPassword = [
          { required: true, message: "请输入SMTP密码", trigger: "blur" }
        ];
      } else {
        this.rules.smtpPassword = [];
      }
    },
    // eslint-disable-next-line object-shorthand
    "formData.isHistory"(newVal) {
      if (!newVal) {
        this.formData.latestNum = 0;
      }
    }
  },
  data() {
    const validateMail = (rule, value, callback) => {
      if (!new RegExp(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(value)) {
        callback(new Error("请检查邮箱格式"));
      } else {
        callback();
      }
    };
    return {
      btnLoading: false,
      dialogVisible: false,
      serverFormVisible: true,
      historyOptions: [
        {
          value: 0,
          label: "最近100封"
        },
        {
          value: 1,
          label: "全部"
        }
      ],
      rules: {
        mailFullAccount: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { validator: validateMail, trigger: "blur" }
        ],
        mailPassword: [
          { required: true, message: "请输入邮箱密码", trigger: "blur" }
        ],
        popServerAddress: [
          { required: true, message: "请输入POP服务器接收地址", trigger: "blur" }
        ],
        popPort: [
          { required: true, message: "请输入POP服务器端口号", trigger: "blur" }
        ],
        smtpServerAddress: [
          { required: true, message: "请输入SMTP服务器接收地址", trigger: "blur" }
        ],
        smtpPort: [
          { required: true, message: "请输入SMTP服务器端口号", trigger: "blur" }
        ],
        smtpPassword: [
          { required: true, message: "请输入SMTP密码", trigger: "blur" }
        ]
      },
      serverSettings: { ...serverSettings },
      formData: { ...formData }
    };
  },
  methods: {
    submitHandler() {
      this.$refs.basicForm.validate((valid) => {
        // eslint-disable-next-line no-useless-return
        if (!valid) return;
        this.$refs.serverForm.validate((valid) => {
          if (valid) {
            if (this.serverSettings.isMailPwd === 0) {
              this.serverSettings.smtpPassword = "";
            }
            this.$emit("submitForm", {
              ...this.formData,
              serverSettings: JSON.stringify(this.serverSettings)
            });
            this.btnLoading = true;
          }
        });
      });
    },
    reset() {
      this.formData = { ...formData };
      this.serverSettings = { ...serverSettings };
    },
    show(data) {
      if (data) {
        this.formData.isHistory = data.latestNum === -1 || data.latestNum === undefined ? -1 : 0;
        Object.assign(this.formData, data);
        if (data.serverSettings) {
          Object.assign(this.serverSettings, data.serverSettings);
        }
      }
      this.dialogVisible = true;
    },
    hide() {
      this.btnLoading = false;
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.account-dialog {
  .el-dialog__footer {
    padding-top: 0px !important;
  }
  .el-dialog__body {
    padding-top: 10px !important;
  }
}
.account-form {
  .checkbox-item {
    margin: 0 0 10px 0;
  }
  .form-title {
    font-size: 12px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .fold-title {
    cursor: pointer;
    .el-icon-arrow-down {
      transition: all .3s;
      color: #3e90fe;
      font-size: 14px;
      margin-left: 4px;
    }
    .rotate-arrow {
      transform: rotate(-90deg);
    }
  }
  .border {
    width: 100%;
    height: 1px;
    background: #e8ecf2;
    margin: -10px 0 20px 0;
  }
  .seat {
    height: 250px;
  }
}
</style>
