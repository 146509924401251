<template>
  <div class="mail-setting-wrapper">
    <top-header :nav-name="navName" @backHandle="backHandle"></top-header>
    <div class="mail-setting-container">
      <container-left></container-left>
      <div class="container-right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/MailSend/TopHeader";
import ContainerLeft from "../../components/MailSetting/ContainerLeft";

export default {
  name: "MailSetting",
  components: {
    TopHeader,
    ContainerLeft
  },
  data() {
    return {
      navName: "邮箱设置"
    };
  },
  methods: {
    backHandle() {
      this.$router.push({
        path: "/mailbox/list"
      });
    }
  },
  mounted() {
  }
};
</script>

<style lang="scss" scope>
.mail-setting-wrapper {
  height: 100%;
  background: #ecf1f7;
  .mail-setting-container {
    height: calc(100% - 50px);
    display: flex;
    padding: 10px;
    .container-right {
      flex: 1;
      background: #fff;
      border-radius: 4px;
      overflow: hidden;
      .table-wrap {
        margin-top: 10px;
      }
    }
  }
}
</style>
