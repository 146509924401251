var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "10vh",
        title: _vm.dialogTitle,
        "custom-class": "account-dialog",
        visible: _vm.dialogVisible,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "account-form" },
        [
          _c("div", { staticClass: "form-title" }, [_vm._v("基础信息设置")]),
          _c(
            "el-form",
            {
              ref: "basicForm",
              attrs: {
                "label-position": "right",
                rules: _vm.rules,
                "label-width": "70px",
                model: _vm.formData,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "邮箱地址", prop: "mailFullAccount" } },
                [
                  _c("input", {
                    staticStyle: {
                      border: "none",
                      height: "0",
                      width: "0",
                      display: "inline",
                      padding: "0",
                    },
                    attrs: { type: "password" },
                  }),
                  _c("el-input", {
                    attrs: { placeholder: "请输入邮箱地址" },
                    model: {
                      value: _vm.formData.mailFullAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mailFullAccount", $$v)
                      },
                      expression: "formData.mailFullAccount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱密码", prop: "mailPassword" } },
                [
                  _c("input", {
                    staticStyle: {
                      border: "none",
                      height: "0",
                      width: "0",
                      display: "inline",
                      padding: "0",
                    },
                    attrs: { type: "text" },
                  }),
                  _c("input-password", {
                    attrs: {
                      "show-password": _vm.dialogType === "add",
                      placeholder: "请输入邮箱密码",
                    },
                    model: {
                      value: _vm.formData.mailPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mailPassword", $$v)
                      },
                      expression: "formData.mailPassword",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "其它选项" } }, [
                _c(
                  "p",
                  { staticClass: "checkbox-item" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 0, "false-label": 1 },
                        model: {
                          value: _vm.formData.intervalMinutes,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "intervalMinutes", $$v)
                          },
                          expression: "formData.intervalMinutes",
                        },
                      },
                      [_vm._v("定时收取邮件")]
                    ),
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticClass: "checkbox-item" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 0, "false-label": -1 },
                        model: {
                          value: _vm.formData.isHistory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isHistory", $$v)
                          },
                          expression: "formData.isHistory",
                        },
                      },
                      [_vm._v("收取历史邮件")]
                    ),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formData.isHistory === 0,
                            expression: "formData.isHistory === 0",
                          },
                        ],
                        staticStyle: { width: "350px", "margin-left": "12px" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.formData.latestNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "latestNum", $$v)
                          },
                          expression: "formData.latestNum",
                        },
                      },
                      _vm._l(_vm.historyOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "border" }),
          _c(
            "div",
            {
              staticClass: "form-title fold-title",
              on: {
                click: function ($event) {
                  _vm.serverFormVisible = !_vm.serverFormVisible
                },
              },
            },
            [
              _vm._v(" 服务器设置 "),
              _c("span", {
                staticClass: "el-icon-arrow-down",
                class: { "rotate-arrow": !_vm.serverFormVisible },
              }),
            ]
          ),
          _c(
            "el-collapse-transition",
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.serverFormVisible,
                      expression: "serverFormVisible",
                    },
                  ],
                  ref: "serverForm",
                  attrs: {
                    "label-position": "right",
                    rules: _vm.rules,
                    "label-width": "82px",
                    model: _vm.serverSettings,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "接收服务器", prop: "popServerAddress" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入POP服务器接收地址" },
                        model: {
                          value: _vm.serverSettings.popServerAddress,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.serverSettings,
                              "popServerAddress",
                              $$v
                            )
                          },
                          expression: "serverSettings.popServerAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "端口号", prop: "popPort" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入POP服务器端口号" },
                        model: {
                          value: _vm.serverSettings.popPort,
                          callback: function ($$v) {
                            _vm.$set(_vm.serverSettings, "popPort", $$v)
                          },
                          expression: "serverSettings.popPort",
                        },
                      }),
                      _c(
                        "div",
                        { staticStyle: { margin: "10px 0 -10px 0" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 0, "false-label": 1 },
                              model: {
                                value: _vm.serverSettings.popUseSsl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serverSettings, "popUseSsl", $$v)
                                },
                                expression: "serverSettings.popUseSsl",
                              },
                            },
                            [_vm._v("始终使用安全连接SSL")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "发送服务器", prop: "smtpServerAddress" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入SMTP服务器发送地址" },
                        model: {
                          value: _vm.serverSettings.smtpServerAddress,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.serverSettings,
                              "smtpServerAddress",
                              $$v
                            )
                          },
                          expression: "serverSettings.smtpServerAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "端口号", prop: "smtpPort" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入SMTP服务器端口号" },
                        model: {
                          value: _vm.serverSettings.smtpPort,
                          callback: function ($$v) {
                            _vm.$set(_vm.serverSettings, "smtpPort", $$v)
                          },
                          expression: "serverSettings.smtpPort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SMTP密码", prop: "smtpPassword" } },
                    [
                      _c("input", {
                        staticStyle: {
                          border: "none",
                          height: "0",
                          width: "0",
                          display: "inline",
                          padding: "0",
                        },
                        attrs: { type: "text" },
                      }),
                      _c("input-password", {
                        attrs: {
                          disabled: _vm.serverSettings.isMailPwd === 0,
                          placeholder: "SMTP默认沿用邮箱密码",
                        },
                        model: {
                          value: _vm.serverSettings.smtpPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.serverSettings, "smtpPassword", $$v)
                          },
                          expression: "serverSettings.smtpPassword",
                        },
                      }),
                      _c(
                        "p",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 0, "false-label": 1 },
                              model: {
                                value: _vm.serverSettings.isMailPwd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serverSettings, "isMailPwd", $$v)
                                },
                                expression: "serverSettings.isMailPwd",
                              },
                            },
                            [_vm._v("SMTP默认沿用邮箱密码")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 0, "false-label": 1 },
                              model: {
                                value: _vm.serverSettings.smtpUseSsl,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.serverSettings,
                                    "smtpUseSsl",
                                    $$v
                                  )
                                },
                                expression: "serverSettings.smtpUseSsl",
                              },
                            },
                            [_vm._v("始终使用安全链接SSL")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.serverFormVisible,
                expression: "!serverFormVisible",
              },
            ],
            staticClass: "seat",
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "en-button",
            {
              staticStyle: { "font-size": "12px" },
              attrs: {
                type: "primary",
                loading: _vm.btnLoading,
                size: "medium",
              },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }