<template>
   <el-dialog
    title=""
    custom-class="name-list-dialog"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="700px"
  >
    <span slot="title">
      <span class="title">{{titleInfo.title}} &nbsp;&nbsp;</span>
      <el-tooltip class="tips" effect="dark" :content="titleInfo.tips" placement="bottom-start">
        <i class="iconfont">&#xec26;</i>
      </el-tooltip>
    </span>
    <div class="filed-item">
      <span class="label">邮箱</span>
      <div class="input-wrapper">
        <input-tag v-model="contactList" placeholder="请输入"></input-tag>
        <span class="iconfont" @click="showMailList">&#xe6b3;</span>
      </div>
      <mail-list-dialog ref="mailListDialog" @confirmSelect="confirmSelect"></mail-list-dialog>
    </div>
    <span slot="footer">
      <el-button type="primary" style="font-size: 12px;" size="medium" @click="confirmAdd">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// 黑白名单弹窗
import InputTag from "../MailSend/InputTag/InputTag";
import MailListDialog from "../MailSend/MailListDialog";

export default {
  name: "NameListDialog",
  components: {
    InputTag,
    MailListDialog
  },
  props: {
    // 是否是黑名单
    isBlackList: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    titleInfo() {
      if (this.isBlackList) {
        return {
          title: "新增黑名单",
          tips: "可以添加一个邮件地址，如：mail@example.com或者是一个域@example.com ,添加后将拒收该地址来信."
        };
      }
      return {
        title: "新增白名单",
        tips: "可以添加一个邮件地址，如：mail@example.com或者是一个域@example.com ,添加后绕过邮件过滤功能进入收件箱."
      };
    }
  },
  data() {
    return {
      dialogVisible: false,
      contactList: []
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    showMailList() {
      this.$refs.mailListDialog.show();
    },
    confirmSelect(list) {
      const formatList = list.map((item) => ({
        mailAccount: item.address,
        mailName: item.name,
        userId: item.id
      })).filter((item) => !this.contactList.find((contact) => contact.mailAccount === item.mailAccount));
      this.contactList = [...this.contactList, ...formatList];
    },
    confirmAdd() {
      setTimeout(() => {
        this.$emit("confirmAdd", this.contactList);
        this.dialogVisible = false;
        this.contactList = [];
      }, 300);
    }
  }
};
</script>
<style lang="scss">
.tips {
  color: #f7bf27;
}
.name-list-dialog {
  .new-tag {
    margin-top: 7px;
    margin-left: 3px;
  }
}
</style>
<style lang="scss" scoped>
.name-list-dialog {
  .title {
    color: #333333;
  }
  .el-icon-info {
    font-size: 16px;
    color: #f7bf27;
  }
  .filed-item {
    display: flex;
    align-items: center;
    padding: 10px 0 20px 0;
    .label {
      color: #333333;
      font-size: 12px;
      margin-right: 12px;
    }
    .input-wrapper {
      display: flex;
      flex: 1;
      line-height: 32px;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      .iconfont {
        position: relative;
        display: inline-block;
        width: 32px;
        font-size: 18px;
        line-height: 32px;
        color: #a9b5c6;
        cursor: pointer;
        text-align: center;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 22px;
          background: #e8ecf2;
          top: 4px;
          left: -4px;
        }
      }
    }
  }
}
</style>
