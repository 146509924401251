<!--
 * @Author: your name
 * @Date: 2021-03-15 11:38:33
 * @LastEditTime: 2021-03-15 11:57:58
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \mailbox\src\components\MailSetting\ContainerLeft.vue
-->
<template>
  <div class="container-left">
    <div class="title">常规设置</div>
    <div class="setting-list">
      <div class="setting-item" :class="{'active-item': routeName === 'AccountManage'}" @click="jump('/accountManage')">
        <i class="iconfont icon-manage">&#xe7bc;</i>账号管理
      </div>
      <div class="setting-item" :class="{'active-item': routeName === 'FilterMail'}" @click="jump('/filterMail')">
        <i class="iconfont icon-filter">&#xe6ad;</i>邮件过滤
      </div>
      <div class="setting-item" :class="{'active-item': routeName === 'NameListSetting'}" @click="jump('/nameListSetting')">
        <i class="iconfont icon-name">&#xe6ae;</i>黑/白名单
      </div>
      <div class="setting-item" :class="{'active-item': routeName === 'ReceiveSetting'}" @click="jump('/receiveSetting')">
        <i class="iconfont icon-setting">&#xe6af;</i>收发邮件设置
      </div>
      <div class="setting-item" :class="{'active-item': routeName === 'AutoGraphSetting'}" @click="jump('/autoGraphSetting')">
        <i class="iconfont icon-sign">&#xe6c8;</i>签名设置
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContainerLeft",
  data() {
    return {};
  },
  methods: {
    jump(path) {
      const prefix = "/mailbox/mailSetting";
      path = prefix + path;
      if (path !== this.$route.path) {
        this.$router.push(path);
      }
    }
  },
  computed: {
    routeName() {
      return this.$route.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.container-left {
  width: 318px;
  // height: 100%;
  background: #fff;
  border-radius: 6px;
  margin-right: 10px;
  .title {
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8ecf2;
    text-indent: 20px;
    font-size: 14px;
    color: #333333;
    font-weight: bold;
  }
  .setting-list {
    padding: 20px;
    .setting-item {
      height: 36px;
      line-height: 34px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #e8ecf2;
      box-sizing: border-box;
      color: #636c78;
      font-size: 12px;
      text-indent: 12px;
      cursor: pointer;
      margin-bottom: 14px;
      .iconfont {
        position: relative;
        top: 1px;
        font-size: 16px;
        margin-right: 4px;
      }
      .icon-manage {
        color: #3e90fe;
      }
      .icon-filter {
        color: #9366eb;
      }
      .icon-name {
        color: #3e90fe;
      }
      .icon-setting {
        color: #26c393;
      }
      .icon-sign {
        color: #d69e79;
      }
    }
    .active-item {
      color: #fff;
      background: #3e90fe;
      border-color: #3e90fe;
      .iconfont {
        color: #fff;
      }
    }
  }
}
</style>
